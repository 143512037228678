@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;

}

.imageWrapper {

}

.image {
  font-size: 120px;
  color: $-text-additional;
}
.title {
  color: $-text-additional;
}

.icon {
  font-size: 100px;
}