@import "src/components/palette.module.scss";

.component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.menu {
  width: 300px;
  //background-color: antiquewhite;
  border-right: 1px solid #e7ebef
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  overflow: auto;
}

.companyDropDownWrapper {
  border-bottom: 1px solid #ddd;
  height: 60px;
}
