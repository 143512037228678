.presentationItem {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
}

.presentationMapPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
.imageUpload {
  margin: 0;
  width: 250px;
  height: 150px;
  border: 0;
  &:hover {
    border: 0;
  }
}

.imageUploadPlaceholder {
  z-index: 2;
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
  border: 0;
}