@import "src/components/palette.module.scss";

.component {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  text-transform: none;
  padding: 10px;
}

.primary {
  background: $-brand-color;
  color: #fff;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0 0 20px 20px inset;
  }
}

.secondary {
  border: 1px solid $-grey;
  color: $-text;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0 0 20px 20px inset;
  }
}

.component.disabled {
  background-color: $-grey-light;
  color: $-text;
  cursor: not-allowed;
}

.text {
  text-decoration: underline;
  &:hover {
    background-color: rgba(0,0,0,0.2);
    text-decoration: none;
  }
}

.showTooltipText {
  position: relative;
  &:before {
    content:'';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.startIcon {
  color: inherit;
}

.toolbar {
  font-family: Inter, sans-serif;
  font-style: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: .3s ease;
  max-height: 100%;
  cursor: pointer;
  box-shadow: 0 1px 1px rgb(0 120 212 / 12%), 0 0 0 1px rgb(0 120 212 / 8%), 0 0 5px 2px rgb(60 0 87 / 5%), inset 0 10px 10px rgb(0 120 212 / 7%);
  border: 1px solid $-brand-color;
  color: $-brand-color;
  line-height: 24px;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02857em;

  &:hover {
    background-color: rgba(25, 118, 210, 0.04);
  }
}

.fullWidth {
  width: 100%;
}

.small {
  padding: 5px 10px;
}