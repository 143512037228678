.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 20px 20px;
}

.justCancel {
  padding-bottom: 0;
}

.title {
  display: flex;
  flex: 1 1 auto;
  font-weight: 700;
}

.content {
  padding: 10px 20px;
}

.paper {
  min-width: 350px;
  max-width: 90%;
  max-height: 90%;
}

.closeIcon {
    cursor: pointer;
}

.mobileFullScreenPaper {
  max-width: unset;
  max-height: unset;
}