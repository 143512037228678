.component {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.orSocialTitle {
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 250px;
  gap: 10px;
  color: rgba(0,0,0,0.7);
  margin: 15px 0;
  font-weight: 600;
  span {
    height: 1px;
    display: flex;
    flex: 1 1;
    background: #ccc;
  }
}