.component {
  min-width: 95px;

  input {
    padding: 8px 0 9px 10px  ;
  }
  fieldset {
    border-radius: 8px;
    border-color: #B9B9C6;
  }
  label {
    top: -8px;
  }
  :global {
    .MuiFormLabel-filled {
      top: 0
    }
    .MuiInputAdornment-root {
      margin: 0;

      //.MuiButtonBase-root {
      //  padding: 0;
      //}
    }
  }


}

.popper {

  :global {
    .MuiList-root {
      &:after {
        display: none;
      }
      li {
        height: 28px;
        overflow: hidden;
        //border: 1px solid red;
        min-height: unset;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.88);

        &.Mui-selected {
          background: #e6f4ff;

        }
      }
    }
    .MuiDialogActions-root {
      //display: none;
      button {
        height: 20px;
        border-radius: 8px;
        font-weight: 800;
        border: 1px solid;
        width: 40px;
        min-width: unset;
      }
    }
  }
}