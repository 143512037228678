@import "src/components/palette.module.scss";

.component {

}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  //padding: 10px 15px;
  .NavLink {
    cursor: pointer;
    text-decoration: none;
    color: rgb(62, 80, 96);
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 15px;
    user-select: none;
    &:hover {
      color: #1D1D1D;
      text-decoration: underline;
    }
  }
  a {
    border-left: 3px solid $-menu-item-border-color;
  }
  :global {
    a.active {
      //border-left: 3px solid #70bdc1;
      //background: rgba(220, 243, 245,0.7);
      background: $-menu-item-active-background;
      border-color: $-menu-item-active-border-color;
      :global(.Icon) {
        color: $-menu-text-active-color;
      }
    }
  }
  &.disabled {
    .NavLink {
      cursor: default;
      opacity: 0.4;
      color: inherit;
      text-decoration: none;
    }
  }

}

.icon {
  display:flex;
  margin-right: 10px;

  :global(.Icon) {
    font-size: 20px;
    color: $-menu-text-color;
  }
}
