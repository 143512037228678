.component {
  padding: 10px 5px 5px 5px;
}

.autoComplete {
  max-width: 100%;

  input::selection {
    background: transparent;
  }
}