.component {
  display: flex;
  margin-top: 10px;
}

.alignRight {
  justify-content: end;
}

.alignCenter {
  justify-content: center;
}