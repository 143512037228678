@import "src/components/palette.module.scss";

.component {
  &.inputSmall {
    :global {
      //.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
      //  padding: 5px 4px 7px 6px;
      //}
      .MuiChip-root {
        min-height: 5px;
        height: 22px;

        svg {
          width: 16px;
        }
      }
      .MuiChip-label {
        font-size: 14px;
      }
    }
  }
  :global(.MuiInputBase-root) {
    border-radius: 8px;
    position: relative;
    z-index: 2;
    &:hover:not(:global(.Mui-focused)):not(:global(.Mui-disabled)):not(:global(.Mui-error)) {
      fieldset {
        border-color: #B9B9C6;
      }
    }
  }
  :global(.Mui-focused) {
    fieldset {
      border-color: $-brand-color;
    }
  }


  &.formMargin:global(.MuiFormControl-root) {
    margin-bottom: $-form-margin
  }
  //&.marginBottom:global(.MuiFormControl-root) {
  //  margin-bottom: 20px;
  //}

  //&:global(.MuiInputAdornment-root) {
  //  height: unset;
  //    & > div {
  //      display: flex;
  //      align-items: center;
  //    }
  //}
  //:global{
  //  .MuiInputLabel-root {
  //    background: #fff;
  //    padding: 2px 4px;
  //    border-radius: 4px;
  //    top: 0;
  //    left: -2px;
  //  }
  //  .MuiInputAdornment-root {
  //    height: unset;
  //    & > div {
  //      display: flex;
  //      align-items: center;
  //    }
  //  }
  //}

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Style the input to prevent resizing in some browsers */
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

.readOnly {
  input::selection {
    background-color: transparent;
  }
  input {
    cursor: pointer;
  }
}
