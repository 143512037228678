@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid rgb(224, 227, 231);
  border-radius: $-border-radius;
  padding: 5px 10px;
}

.marginBottomDefault {
  margin-bottom: 10px;
}

.flexStretched {
  flex: 1 1;
}