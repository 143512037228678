.component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.headerTitle {
  font-weight: 600;
  align-items: center;
  display: flex;
}

.toolbar {
  align-items: center;
  display: flex;
}