.component {
  //background-color: #ccc;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.content {
  display: flex;
}

:global(.isMobile) {
  .content {
    flex-direction: column;
  }

}