@import "src/components/palette.module.scss";

.preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  //border-radius: 5px;
  border: 1px solid transparent;
  //border-color: $-grey;
  border-radius: 0;
  //margin-left: 100px;
  position: relative;

  &.open {
    border-radius: 5px 5px 0 0 ;
  }
  &:hover,
  &.open{
    border-color: $-grey;
    //z-index: 10000;
    background: #fff;
  }
}


.previewExistedCompany {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.addNew {
  padding: 5px 10px;
  display: flex;
  height: 42px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: $-menu-item-active-background;
  }
}

.addNewIcon {
  color: #a5b7cb;
  font-size: 24px;
  margin-right: 13px;
  margin-left: 3px;
}

.iconButton {
  transform: rotate(-90deg);
}