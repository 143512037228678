@import "src/components/palette.module.scss";

.poppover {
  background: rgba(0,0,0,0.3);
}
.paper {
  left: 0 !important;
  width: 299px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-left: 1px solid $-grey;
  border-right: 1px solid $-grey;
}
.paperMobile {
  top: 0 !important;
  border-left: 0;
}

.companyMenu {
  background: rgba(0,0,0,0.03);
  border-bottom: 1px solid $-grey;
}

.companiesList {
  //max-height: 300px;
  overflow: auto;
}

.listItem {
  border-bottom: 1px solid $-grey;
  cursor: pointer;
  &:hover {
    background-color: $-menu-item-active-background;
  }
}
.listItemMobile {
  &:hover {
    background-color: none;
  }
}

.selected {
  background-color: rgba(182, 203, 223, 0.3);
}

.poppoverFooter {

}

.addNew {
  padding: 5px 10px;
  display: flex;
  height: 42px;
  align-items: center;
  cursor: pointer;
  width: 100%;
  &:hover {
    background-color: $-menu-item-active-background;
  }
}
.companiesListTitle {
  padding: 5px 10px;
  font-size: 11px;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
}

.companiesList {
  font-weight: 300;
}

.addNewIcon {
  color: #a5b7cb;
  font-size: 24px;
  margin-right: 13px;
  margin-left: 3px;
}

.visibleScroll {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

.companyPreviewMobile {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  :global(.MuiSvgIcon-root) {
    display: none;
  }
}

.drawer {
  :global(.MuiDrawer-paper) {
    width: 300px;
  }
}

.backLine {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 5px;
  background: $-menu-item-active-background;
}
.backIcon {
  transform: rotate(90deg);
}
