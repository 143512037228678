@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
}

.header {
  min-height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative;
}

.headerContent {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background: $-main-layout-background;
  padding: 20px;
  height: 100%;
  overflow: scroll;
}

.loading {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.loadingOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgb(250 250 250 / 80%);
}



:global(.isMobile) {
  .header:not(.headerInitWizardPageStyles) {
    padding-left: 0;
  }
  .burgerButton {
    margin: 0 10px;
  }

  .content {
    padding: 20px 10px;
  }
}