@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');

:global {
  * {
    font-family: 'IBM Plex Sans', sans-serif;
    //font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #FFF;
  }

  #root {
    height: 100%;
    //min-height: 100vh;
    background: #FFF;
  }

  html {
    overflow: hidden;
    //width: 100%;
  }
  body {
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.component {
  width: 100%;
  height: 100%;
}

//.component {
//  //font-size: 30px;
//  display: flex;
//  justify-content: center;
//  //align-items: center;
//  height: 100%;
//  //font-family: Roboto, sans-serif;
//  background-color: #69c0c0;
//}

