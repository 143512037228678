@import "src/components/palette.module.scss";

.companyOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.companyLogoBox {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
}

.selected {
  background-color: $-menu-item-active-background;
}