.component {
  min-width: 300px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
}

.searchBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.searchInput {
  display: inline-flex;
  flex: 1 1 auto;
}

.mapBox {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}


.mapPlaceholder {
  width: 100%;
  display: flex;
  background-color: #e3e3e3;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}

.mapEmbed {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}