$-grey: #ccc;
$-grey-light: #f0f0f0;

$-logo-color: #86d2ff;
$-brand-color: #1976d2;
$-brand-color-light-200: #ddecfb;
$-brand-color-light: #ddecfb;
$-header-background-color: $-brand-color;
$-button-color: #fff;

$-border-color: #e7ebef;
$-border-radius: 8px;
//$-border-color: #e0e3e7;

$-text: #333;
$-text-additional: #aaa;

$-icon-default: #aaa;

$-red: #ff2d55;

$-green: #4caf50;

$-success: $-green;

$-orange: #ff9800;


$-menu-item-background: transparent;
$-menu-item-active-background: #E2EDF8;

$-menu-item-border-color: transparent;
$-menu-item-active-border-color: #91B9E3;

$-menu-text-color: rgb(62, 80, 96);
$-menu-text-active-color: #1D1D1D;


// forms
$-form-margin: 20px;


$-checkbox-color: $-brand-color;

$-main-layout-background: #F3F6F9;