.component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.item {
  margin-left: 8px;
  text-transform: capitalize;
  cursor: pointer;
  color: #929292;

  &:first-child {
    margin-left: 0;
  }
}

.selected {
  text-decoration: underline;
}