@import "src/components/palette.module.scss";

.component {
  position: relative;
  overflow: hidden;

}

.editMode {
  .editControl {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.editControl {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}


.content {
  opacity: 1;
}

.mapMode {
  &:hover {
    .content {
      opacity: 0.5;
    }
  }
  .content {
    position: absolute;

    left: -20px;
    top: -80px;
    bottom: -110px;
    right: -20px;
    & > * {
      height: 100%;
      width: 100%;
    }
  }
}

.imageMode {
  .content {
    width: 100%;
    height: 100%;
  }
  .content > div {
    height: 100%;
    background-color: transparent;
    border: 0;
  }
}

.clearControl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-size: 23px;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
    .clearControlIcon {
      color: $-brand-color
    }
  }
}

.readOnly .content {
  opacity: 1 !important;
}

