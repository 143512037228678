.mapContainer {
  width: 600px;
  height: 500px;
}

:global(.isMobile) {
  .mapContainer {
    max-width: 100%;
    aspect-ratio: 1/1;
    height: auto;
  }
}