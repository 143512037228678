.component {

}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: 10px 15px;
  a {
    text-decoration: none;
    //color: #696262;
    color: rgb(62, 80, 96);
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    &:hover {
      color: #1D1D1D;
      text-decoration: underline;
    }
  }
  :global {
    a.active {
      text-decoration: underline;
    }
  }
}