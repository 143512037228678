@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700');

.component {
  display: flex;
  text-decoration: none;
  img {
    width: 60px;
    height: 60px;
    margin-right: 5px;
    filter: saturate(4);
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  p {
    color: #fff;
    margin: 0;
    margin-bottom: -3px;
  }

  p, span {
    font-size: 19px;
    //color: #fff;
    color: #929292;
    font-family: "Comfortaa", cursive;
  }
}