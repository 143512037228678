@import "src/components/palette.module.scss";

.component {

}
.primary {
  background-color: $-red;
}

.text {
  color: $-red;

  &:hover {
    background-color: rgba(255, 45,85, 0.21);
  }
}
