.component {
  width: 100%;
  height: 150px;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(0,0,0,0.03);
  &.placeHolderMode:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
    .placeholder {
      opacity: 1;
    }
  }
}

.placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  color: rgba(0,0,0,0.7);
  cursor: pointer;
  opacity: 0.7;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}

.inputPlaceholder {
  opacity: 0;
}

.imagePreview {
  height: 100%;
  text-align: center;
  img {
    max-height: 100%;
  }
}

.clearButtonBox {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  color: #1876d1;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}