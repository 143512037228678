.component {
  display: inline-flex;
  gap: 7px;
}

.block {
  display: flex;
  flex-direction: row;
}

.align-center {
  justify-content: center;
}

.align-left {
  justify-content: start;
}

.align-right {
  justify-content: end;
}