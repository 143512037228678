.component {
  position: relative;

  :global(.MuiInputBase-root) {
    padding: 0;
  }

  input {
    padding-right: 40px;
  }
}

.visibilityIcon {
  position: absolute;
  right: 15px;
}