@import "src/components/palette.module.scss";

.component {

}

.box {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formContent {
  display: flex;
  flex-direction: row;
}

.logoBox {
  width: 100%;
  aspect-ratio: 1/1;
}

.section {
  display: flex ;
  flex-direction: column;
  flex: 1 1 auto;
  border-left: 1px solid $-grey;
  &:first-child {
    border-left: 0;
  }
}

.sectionCompanyModifier {
  width: 400px;
}

.sectionCompany {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sectionCompanyContent {

}
.sectionLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectionLogoButtons {
  margin-bottom: 20px;
}

.companyInput {
  width: 100%;
}
.domainBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.domainInput {
  width: 150px;
  &:global(.MuiFormControl-root) {
    margin-bottom: 0;
  }
  margin-right: 5px;

}
.sectionHeader {
  padding: 5px;
  border-bottom: 1px solid $-grey;
  width: 100%;
}

.sectionContent {
  padding: 10px;
}

.organizationInput {
  width: 100%;
}

.buttons {
  border-top: 1px solid $-grey;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.phoneInput {
  margin-top: 20px;
  margin-bottom: 15px;
}


.timeRelatedSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.timeZoneAndCurrency {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 1 1 100%;
}

.timezoneSection {
  display: flex;
  flex: 1 1 100%;
  max-width: 300px;
}

.timezone {
  width: 100%;
}

.currencySection {
  display: flex;
  flex: 1 1;
  min-width: 100px;
}

.currencies {
  width: 100%;
}

.workingHoursSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
  //flex: 1 1 100%;
}

:global(.isMobile) {
  .formContent {
    flex-direction: column;
  }
  .section {
    width: 100%;
    border-left: 0;
  }

  .timeRelatedSection {
    flex-direction: column;
  }
}