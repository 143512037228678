@import "src/components/palette.module.scss";

.component {
  width: 290px;
  
  :global {
    .MuiFormControl-root {
      width: 100%;
    }
  }
}

.textField {
  &:global(.MuiFormControl-root) {
    margin-bottom: 20px;
  }
}

.box {
  padding: 20px;
  border: 0;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.theme2 {

  @media (min-width: 768px) {
    & > div {
      display: flex;
    }

    .logoWrapper {
      margin-right: 10px;
      padding-right: 30px;
      border-right: 1px solid #ccc;
      margin-bottom: 0;
      min-height: 250px;
    }
  }
}
