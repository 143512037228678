.component {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.number {
  width: 30px;
  height: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  border-radius: 100px;
  margin-right: 10px;
}

.title {

}