.mapWithSearch {
  width: 600px;
  height: 500px;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin-bottom: 20px;
  margin-top: -20px;
}


:global(.isMobile) {
  .mapWithSearch {
    max-width: 100%;
    aspect-ratio: 1/1;
    height: auto;
  }
}