@import "src/components/palette.module.scss";

.component {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.03);

  &:hover:not(.readOnly) {
    .imageWrapper {
      opacity: 0.5;
    }
    .buttonsEditMode {
      display: flex;
    }
  }
}

.defaultSize {
  height: 150px;
}

.nativeInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;

  img {
    max-height: 100%;
    max-width: 100%;
    //width: 100%;
    //height: 100%;
    object-fit: cover;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;

  //variant single button
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.buttonsEditMode {
  display: none;
  //background: rgba(0,0,0,0.5);
}

.closeIconControl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-size: 23px;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
    .closeIcon {
      color: $-brand-color
    }
  }
}
