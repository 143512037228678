@import "src/components/palette.module.scss";

.component {
  :global {
    .MuiFormControl-root {
      width: 100%;
    }
  }
}

.textField {
  &:global(.MuiFormControl-root) {
    margin-bottom: 20px;
  }
}

.box {
  padding: 20px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin-left: 5px !important;
    &:first-child {
      margin-left: 0;
    }
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.theme2 {

  @media (min-width: 768px) {
    & > div {
      display: flex;
    }

    .logoWrapper {
      margin-right: 10px;
      padding-right: 30px;
      border-right: 1px solid #ccc;
      margin-bottom: 0;
      min-height: 250px;
    }
  }
}

.restoreAccessButtonBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}
.restoreAccessButton {
  color: $-text-additional ;
  background: none;
  border: 0;
  //text-decoration: underline;
  border-bottom: 1px solid $-text-additional;
  cursor: pointer;
  padding: 0;
  //margin-top: 20px;
  &:hover {
    border-color: transparent;
  }
}

.confirmationBox {
  font-size: 15px;
  color: #929292;
  font-family: "Comfortaa", cursive;
}
.confirmationIcon {
  text-align: center;
  margin-bottom: 10px;
  color: $-logo-color;

  svg {
    font-size: 60px;
  }
}

.buttonsBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}
.restoreAccessButton {
  color: $-text-additional ;
  background: none;
  border: 0;
  border-bottom: 1px solid $-text-additional;
  cursor: pointer;
  padding: 0;
  &:hover {
    border-color: transparent;
  }
}
