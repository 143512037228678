.component {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin: 0 5px;
  flex: 1 1 auto;
  width: 50%;
}

.draggingBox {
  background: lightblue;
}

.draggingItem {
  background: lightgreen;
}